import "./App.css";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function App() {
    const [myPrecinct, setMyPrecinct] = useState("");
    const [voter, setVoter] = useState("");

    // Form validations

    const schema = yup.object().shape({
        reached: yup.boolean().default(false),
        optOut: yup.boolean().default(false),
        comments: yup.string().required("Please enter comments from the call"),
    });

    // Voter record fetch

    const getVoter = () => {
        console.log("getVoter: " + myPrecinct);
        const url =
            "https://werginz.net/api/abrex_getNextVoter.php?precinct=" +
            myPrecinct +
            "&t=" +
            new Date().getTime();

        console.log(url);

        axios.get(url).then((response) => {
            console.log(response.data);
            setVoter(response.data);
        });
    };

    // Handler to get next voter in list

    const doGetNext = (event) => {
        event.preventDefault();
        setMyPrecinct(event.target.precinct.value);
        console.log("doSubmit: " + myPrecinct);
        if (myPrecinct !== "") {
            getVoter();
        }
    };

    // Handler to update voter record

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const doUpdateVoter = (data) => {
        console.log(data);
    };

    return (
        <div className="App">
            <div>
                <h2>Absentee Voter List</h2>
            </div>
            <br />
            <div>
                <form onSubmit={doGetNext}>
                    <label>Enter Your Precinct </label>
                    <input
                        value={myPrecinct}
                        type="text"
                        size="2"
                        maxwidth="4"
                        name="precinct"
                        onChange={(e) => setMyPrecinct(e.target.value)}
                    />
                    <br />
                    <br />
                    <button>Get Next Voter from List</button>
                    <br />
                    <br />
                </form>
            </div>

            <div>
                {myPrecinct && voter.nextInListVoter ? (
                    <div>
                        <div>
                            <b>
                                {voter.nextInListVoter.firstName +
                                    " " +
                                    voter.nextInListVoter.lastName}
                                <br />
                            </b>
                            In Precinct {voter.nextInListVoter.precinctNumber}
                            <br />
                            {"(" +
                                voter.nextInListVoter.phoneNumber.slice(0, 3) +
                                ") " +
                                voter.nextInListVoter.phoneNumber.slice(3, 6) +
                                "-" +
                                +voter.nextInListVoter.phoneNumber.slice(6, 10)}
                        </div>
                        <br />
                        <div>
                            <form onSubmit={handleSubmit(doUpdateVoter)}>
                                <div>
                                    <input
                                        type="checkbox"
                                        {...register("reached")}
                                    />
                                    <label>&nbsp;I made contact</label>
                                    <p>{errors.reached?.message}</p>
                                    <input
                                        type="checkbox"
                                        {...register("optOut")}
                                    />
                                    <label>
                                        &nbsp;Voter asked to be removed from
                                        list
                                    </label>
                                    <p>{errors.optOut?.message}</p>
                                </div>
                                <div>
                                    <textarea
                                        rows={4}
                                        cols={40}
                                        placeholder="Notes from the call..."
                                        {...register("comments")}
                                    />
                                    <p>{errors.comments?.message}</p>
                                </div>
                                <div>
                                    <input
                                        type="submit"
                                        value="Update Record"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p>No voters found for selected precinct</p>
                    </div>
                )}

                <br />
            </div>
        </div>
    );
}

export default App;
